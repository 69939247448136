import { useMemo } from "react";
import { AsyncHookResult } from "@/types/hooks/AsyncHookResult";
import useGraphToken from "@/hooks/graph/useGraphToken";
import useGdasToken from "@/hooks/gdas/useGdasToken";
import Xitadel from "@/services/auth/xitadel";

export default function useXitadelToken(): AsyncHookResult<string | null> {
    const graphToken = useGraphToken();
    const gdasToken = useGdasToken();

    return useMemo<AsyncHookResult<string | null>>(() => ({
        isLoading: graphToken.isLoading || gdasToken.isLoading,
        error: graphToken.error ?? gdasToken.error,
        result: (graphToken.result && gdasToken.result) ? Xitadel.createToken(graphToken.result, gdasToken.result) : null
    }), [graphToken, gdasToken]);
}