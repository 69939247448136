import { FunctionComponent } from 'react';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { history } from '@/util/browser';
import { correlationId } from '@/util/session';

const appInsightsPlugin = new ReactPlugin();

export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: "76933d43-1df4-46a0-bfd8-d49f344bcca4",
        loggingLevelConsole: SeverityLevel.Verbose,
        loggingLevelTelemetry: SeverityLevel.Verbose,
        extensions: [appInsightsPlugin],
        extensionConfig: {
            [appInsightsPlugin.identifier]: {
                history: history
            }
        }
    }
});

appInsights.addTelemetryInitializer((envelop) => {
    const defaultTelemetryData = {
        "correlationId": correlationId
    };

    if (envelop?.data) { // If the telemetry item already has custom data, apply default and override
        envelop.data = {
            ...defaultTelemetryData,
            ...envelop.data
        };
    } else if (envelop) { // If the telemetry item does not have any custom data set default
        envelop.data = defaultTelemetryData;
    }
});

appInsights.loadAppInsights();

export function withAppInsights<T>(Component: FunctionComponent<T>) {
    return withAITracking(appInsightsPlugin, Component, Component.displayName, "page-root");
}