import React from 'react';
import { AppProps } from '@/types/props/AppProps';
import Bootstrapper from '@/components/Bootstrapper';
import Content from '@/components/Content';

export default function App(props: AppProps) {
  return (
    <Bootstrapper {...props}>
      <Content />
    </Bootstrapper>
  );
}