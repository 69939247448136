import "@/resources/styles/not-found-page.css";
import { withAppInsights } from "@/services/telemetry/appinsights";
import { year } from "@/util/time";
import React from "react";

function ErrorScreen() {
    return (
        <div className="not-found-page-root">
            <div className="not-found-page-container">
                <div className="not-found-page-content">
                    <div className="not-found-page-section">
                        <h1>404</h1>
                        <p>Sorry, but we could not find what you are looking for.</p>
                    </div>
                </div>
            </div>
            <div className="not-found-page-footer">
                {"© Microsoft " + year()}
            </div>
        </div>
    );
}

export default withAppInsights(ErrorScreen);