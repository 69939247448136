import { useEffect, useMemo, useState } from "react";
import { AsyncHookResult } from "@/types/hooks/AsyncHookResult";
import useXitadelToken from "@/hooks/xitadel/useXitadelToken";
import Xitadel from "@/services/auth/xitadel";
import useCorrelationId from "@/hooks/xitadel/useCorrelationId";
import { XitadelSamlResponse } from "@/types/services/XitadelSamlResponse";

export default function useXitadelSaml(replyUrl: string): AsyncHookResult<XitadelSamlResponse> {
    const correlationId = useCorrelationId();
    const token = useXitadelToken();
    const [samlResponse, setSamlResponse] = useState<XitadelSamlResponse>();
    const [samlResponseError, setSamlResponseError] = useState<Error | null>(null);
    const [isLoadingSaml, setIsLoadingSaml] = useState<boolean>(true);

    useEffect(() => {
        if (!token.result) return;
        Xitadel.getSaml(correlationId, token.result as string, replyUrl)
            .then(setSamlResponse)
            .catch(setSamlResponseError)
            .finally(() => setIsLoadingSaml(false));
    }, [correlationId, token, replyUrl]);

    return useMemo(() => ({
        isLoading: token.isLoading || isLoadingSaml,
        result: samlResponse as XitadelSamlResponse,
        error: token.error || samlResponseError
    }), [token, isLoadingSaml, samlResponse, samlResponseError]);
}