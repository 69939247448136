import { serialize } from "@/util/json";
import { HeaderCollection, Response } from "@/types/util/http";

export enum Method {
    GET = "GET",
    HEAD = "HEAD",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    CONNECT = "CONNECT",
    OPTIONS = "OPTIONS",
    TRACE = "TRACE",
    PATCH = "PATCH"
}

export async function send<T>(method : Method, url: string, headers? : HeaderCollection, body? : object) : Promise<Response<T>> {
    const fetchResponse = await window.fetch(url, {  method, headers, body: body ? serialize(body) : undefined });
    if(fetchResponse.ok){
        const data = await fetchResponse.json();
        return { status: fetchResponse.status,  data: data as T };
    }else{
        const error = await fetchResponse.text();
        return { status: fetchResponse.status, error: error };
    }
}