import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import SplashScreen from "@/components/SplashScreen";
import { withAppInsights } from "@/services/telemetry/appinsights";

function SignOut() {
    const { instance, inProgress, accounts } = useMsal();
    const account = instance.getActiveAccount() || accounts[0];
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if(inProgress !== InteractionStatus.None) return;
        instance.logoutRedirect({ account, logoutHint: account.username, postLogoutRedirectUri: searchParams.get("redirect") });
    }, [inProgress, instance, account, searchParams]);

    return <SplashScreen />;
}

export default withAppInsights(SignOut);