import { correlationId } from "@/util/session";
import { utcNow } from "@/util/time";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "./appinsights";

export class Log {
    public static info(tag: string, message: string): void {
        const msg = `[${correlationId}] (${utcNow()}) ${tag}: ${message}`;

        window.console.log(msg);
        appInsights.trackTrace({ message: msg, severityLevel: SeverityLevel.Information });
    }

    public static warn(tag: string, message: string): void {
        const msg = `[${correlationId}] (${utcNow()}) ${tag}: ${message}`;

        window.console.warn(msg);
        appInsights.trackTrace({ message: msg, severityLevel: SeverityLevel.Warning });
    }

    public static error(tag: string, message: string, error: Error | string, stack = ""): void {
        const msg = `[${correlationId}] (${utcNow()}) ${tag}: ${message}: ${typeof error == "string" ? error : error.message}\n${typeof error == "string" ? stack : error.stack}`;

        window.console.error(msg);
        appInsights.trackTrace({ message: msg, severityLevel: SeverityLevel.Error });

        if (error instanceof Error) appInsights.trackException({ exception: error });
    }
}