import React from "react";
import { useSearchParams } from "react-router-dom";
import useXitadelSaml from "@/hooks/xitadel/useXitadelSaml";
import SplashScreen from "@/components/SplashScreen";
import XitadelSamlForm from "@/components/XitadelSamlForm";
import { withAppInsights } from "@/services/telemetry/appinsights";
import ErrorScreen from "@/components/ErrorScreen";

function Auth() {
  const [searchParams] = useSearchParams();
  const replyUrl = searchParams.get("wreply") as string;
  const saml = useXitadelSaml(replyUrl);

  if (saml.error) {
    return (
      <ErrorScreen
        header="Access Unauthorized"
        showTenantPickerLink={true}        
        tenantPickerReplyUrl={replyUrl}
        error={saml.error.message}
        showControls={false}
      />
    );
  }

  return (
    <>
      <SplashScreen />
      {saml.result && <XitadelSamlForm {...saml.result} />}
    </>
  );
}

export default withAppInsights(Auth);
