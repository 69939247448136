import React, { useEffect, useRef } from "react";
import { XitadelSamlFormProps } from "@/types/props/XitadelSamlFormProps";
import useCorrelationId from "@/hooks/xitadel/useCorrelationId";
import * as http from "@/util/http";

export default function XitadelSamlForm({ saml, replyRecipientUrl } : XitadelSamlFormProps) {
    const correlationId = useCorrelationId();
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if(!formRef.current) return;
        
        formRef.current.submit();
    }, [formRef]);

    return (
        <form ref={formRef} method={http.Method.POST} action={replyRecipientUrl} name="form">
            <input name="CorrelationId" value={correlationId} type="hidden" />
            <input name="SAMLResponse" value={saml} type="hidden"/>
        </form>
    );
}