import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import * as routes from '@/pages/routes';
import SplashScreen from "@/components/SplashScreen";
import { withReplyUrl } from "@/util/withReplyUrl";
import { withAppInsights } from "@/services/telemetry/appinsights";

function CallbackRedirect(){
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        // Wait 5 seconds, if MSAL doesn't perform redirect, we do!
        window.setTimeout(() => setRedirect(true), 5000);
    }, [setRedirect]);

    if(redirect){
        return <Navigate to={withReplyUrl(routes.AUTH_REDIRECT)}/>
    }

    return <SplashScreen/>;
}

export default withAppInsights(CallbackRedirect);