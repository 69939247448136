import React, { useState, useEffect } from 'react';
import '@/resources/styles/splash-screen.css';
import { ReactComponent as XboxLogo } from '@/resources/images/xbox_logo.svg';

function shuffle(array: string[]) {
  let currentIndex = array.length, randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;        
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
}

const messages = shuffle([        
    "Please wait, we're processing your request",
    "Hang on a second, we're almost there",
    "This may take a moment, thanks for your patience",
]);

export default function SplashScreen() {
    const [currentMsg, setCurrentMsg] = useState("");
    
    useEffect(() => {
        let current = 0;
        
        const interval = window.setInterval(() => {
            ++current;
            if (current >= messages.length){
                current = 0;
            }
            setCurrentMsg(messages[current]);
        }, 5000);

        return () => {
            window.clearInterval(interval);
        };
    }, [setCurrentMsg]);
    
  return (
    <div className="splash-root">
      <XboxLogo className="splash-logo" />
      <br/>
      <p>{(currentMsg ? currentMsg + "..." : "")}</p>
    </div>
  );
}