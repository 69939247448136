import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { AUTH_CALLBACK } from "@/pages/routes";
import { getAuthority } from './requests';

export function createPublicClientApp() : IPublicClientApplication{
    const app = new PublicClientApplication({
        auth: {
            clientId: "f2bd2d0c-d443-43af-8ef4-48189d60cf75",
            redirectUri: window.location.origin + AUTH_CALLBACK,            
            authority: getAuthority()
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    });
    return app;
}