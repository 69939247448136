import React, { Component, PropsWithChildren } from "react";
import { Log } from "@/services/telemetry/logger";
import ErrorScreen from "@/components/ErrorScreen";

export default class ErrorBoundary extends Component<PropsWithChildren, { error: Error }> {
    componentDidCatch(error: Error) {
        Log.error(ErrorBoundary.name, "Captured unhandled error", error);
        this.setState({ error });
    }

    render() {
        if (this.state?.error) return <ErrorScreen />;
        return this.props.children;
    }
}