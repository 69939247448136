import { toUrlEncoded } from "./encode";

function getDefaultReplyUrl(): string {
    const domain = window.location.origin;
    if (domain.includes("localhost") || domain.includes("test")) {
        return "https://forumsppeworkspace002eastus2.eastus2.cloudapp.azure.com";
    }else {
        return "https://forums.xboxlive.com";
    }
}

export function withReplyUrl(url: string, reply?: string): string {
    const replyUrl = reply ?? getDefaultReplyUrl();
    return url + (replyUrl ? `?wreply=${toUrlEncoded(replyUrl)}` : "")
}