import React from "react";
import ReactDOM from "react-dom/client";
import "@/resources/styles/index.css";
import { createPublicClientApp } from "@/services/auth/msal";
import { correlationId } from "./util/session";
import App from "@/components/App";
import * as serviceWorkerRegistration from "@/serviceWorkerRegistration";
import reportWebVitals from "@/reportWebVitals";

const publicClientApp = createPublicClientApp();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(<App correlationId={correlationId} publicClientApp={publicClientApp} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
