import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Auth from '@/pages/Auth';
import SignOut from '@/pages/SignOut';
import CallbackRedirect from '@/pages/CallbackRedirect';
import NotFound from '@/pages/NotFound';
import SplashScreen from "@/components/SplashScreen";
import * as routes from '@/pages/routes';
import { withReplyUrl } from '@/util/withReplyUrl';

const SwitchTenant = lazy(() => import('@/pages/SwitchTenant')); // GDAS component is kinda heavy u.u

export default function Content() {
    return (
        <Routes>
            <Route path={routes.ROOT} element={<Navigate to={withReplyUrl(routes.AUTH_REDIRECT)} />} />
            <Route path={routes.AUTH_CALLBACK} element={<CallbackRedirect />} />
            <Route path={routes.AUTH_REDIRECT} element={<Auth />} />
            <Route path={routes.AUTH_SWITCH_TENANT} element={
                <Suspense fallback={<SplashScreen />}>
                    <SwitchTenant />
                </Suspense>
            } />
            <Route path={routes.AUTH_SIGN_OUT} element={<SignOut />} />
            <Route path={routes.NOT_FOUND} element={<NotFound />} />
            <Route path="*" element={<Navigate to={routes.NOT_FOUND} />} />
        </Routes>
    );
}