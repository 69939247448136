import React, { useLayoutEffect, useState } from "react"
import { Router } from "react-router-dom";
import { HistoryRouterProps } from "@/types/props/HistoryRouterProps";
import { history } from "@/util/browser";

export default function HistoryRouter({ children }: HistoryRouterProps) {
    const [historyState, setHistoryState] = useState({ action: history.action, location: history.location });
    useLayoutEffect(() => history.listen(setHistoryState), [setHistoryState]);
    return (
        <Router navigator={history} location={historyState.location} navigationType={historyState.action} >
            {children}
        </Router>
    );
}