import { useEffect, useMemo, useState } from "react";
import { Log } from "@/services/telemetry/logger";
import { AsyncHookResult } from "@/types/hooks/AsyncHookResult";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useActiveAccount } from "@gdx/gdas-react";
import { TokenRequest } from "@/services/auth/requests";

export default function useMsalToken(tokenRequest: TokenRequest, useActiveAccountTenantAuthority = false): AsyncHookResult<string | null> {    
    const { instance } = useMsal();
    const [token, setToken] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [account] = useActiveAccount();

    useEffect(() => {
        if (!account) return;

        const request  = tokenRequest;
        request.account = account;

        if(useActiveAccountTenantAuthority){
            request.authority = `https://login.microsoftonline.com/${account.tenantId}`;
        }

        instance.acquireTokenSilent(request).then((result) => {
            setToken(result.accessToken);            
        }).catch((err) => {
            if (error instanceof InteractionRequiredAuthError) {
                Log.error(useMsalToken.name, "InteractionRequiredAuthError acquiring token silently, trying to acquire token using redirect flow", err.errorMessage, err.stack);
                instance.acquireTokenRedirect(request).catch(() => {
                    Log.error(useMsalToken.name, "Error acquiring token with redirect", err.errorMessage, err.stack);
                    setError(err);
                }).finally(() => {
                    setIsLoading(false);
                });
            } else {
                Log.error(useMsalToken.name, "Error acquiring token silently", err.errorMessage, err.stack);
                setError(err);
            }
        }).finally(() => {
            setIsLoading(false);
        });

    }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

    return useMemo<AsyncHookResult<string | null>>(() => ({
        result: token,
        error: error,
        isLoading: isLoading
    }), [token, error, isLoading]);
}