/* esline-disable @typescript-eslint/no-explicit-any */

type Cache = {
    [key : string] : {
      data: any,
      deps: any[]
    }
}

const _cache : Cache = {};

export function cacheFn<T>(id : string, deps : any[], factory : () => T) {
    const cached = _cache[id];
    let changed = false;

    if (cached) {
      changed = deps.some((dep, i) => dep !== cached.deps[i]);
    }    
    
    if (!cached || changed) {
      const data = factory();
      _cache[id] = { data, deps };
      return data;
    }

    return cached.data;
}