import { cacheFn } from "@/util/cache";
import { AccountInfo } from "@azure/msal-browser";

const SAFE_ROLLOUT_PARAM = {
  safe_rollout: "apply:5a1d4fa8-7558-44bf-b453-38c36e2c2451",
};

const BASE_REQUEST = {
  extraQueryParameters: { ...SAFE_ROLLOUT_PARAM },
  tokenQueryParameters: { ...SAFE_ROLLOUT_PARAM },
};

export const getAuthority = () =>`https://login.microsoftonline.com/${window.localStorage.getItem("ms_xdf_selected_tenant") || "organizations"}`;

export type TokenRequest = {
  authority?: string;
  account?: AccountInfo;
  scopes: string[];
};

export const getGraphRequest = (): TokenRequest => cacheFn(getGraphRequest.name, [getAuthority()], () => ({
    ...BASE_REQUEST,
    authority: getAuthority(),
    scopes: ["User.Read"],
}));

export const getGdasRequest = (): TokenRequest => cacheFn(getGdasRequest.name, [getAuthority()], () => ({
  ...BASE_REQUEST,
  authority: getAuthority(),
  scopes: ["https://prod.gdas.services.gamestack.com/Claims.Read"],
}));