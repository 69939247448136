import React from 'react';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { getGraphRequest } from '@/services/auth/requests';
import { BootstraperProps } from '@/types/props/BootstrapperProps';
import HistoryRouter from '@/components/HistoryRouter';
import { XitadelProvider } from '@/components/XitadelProvider';
import SplashScreen from '@/components/SplashScreen';
import ErrorBoundary from '@/components/ErrorBoundary';

export default function Bootstrapper({ publicClientApp, children, correlationId }: BootstraperProps) {
    return (
        <XitadelProvider value={{ correlationId }}>
            <ErrorBoundary>
                <HistoryRouter>
                    <MsalProvider instance={publicClientApp}>
                        <MsalAuthenticationTemplate
                            interactionType={InteractionType.Redirect}
                            authenticationRequest={getGraphRequest()}
                            loadingComponent={SplashScreen}>
                            {children}
                        </MsalAuthenticationTemplate>
                    </MsalProvider>
                </HistoryRouter>
            </ErrorBoundary>
        </XitadelProvider>
    );
}