import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export function reloadTab(): void {
    // eslint-disable-next-line
    window.location = window.location;
}

export function wipeSiteCookies(path = "/"): void {
    const cookies = window.document.cookie.split(";");
    for (const cookie of cookies) {
        window.document.cookie = cookie.replace(/^ +/, "")
            // setting the expiration to NOW will make browser delete it
            .replace(/=.*/, "=;expires=" + new window.Date().toUTCString() + ";path=" + path);
    }
}

export function wipeSiteLocalStorage(): void {
    window.localStorage.clear();
}