import { XitadelTokenCollection } from "@/types/services/XitadelTokenCollection";
import { serialize } from "@/util/json";
import { toBase64, toUrlEncoded } from "@/util/encode";
import { XitadelSamlResponse, XitadelErrorResponse } from "@/types/services/XitadelSamlResponse";
import { Log } from "@/services/telemetry/logger";
import { XitadelResponse } from "@/types/services/XitadelResponse";
import * as http from "@/util/http";

export default class Xitadel {
    public static createToken(graphToken: string, gdasToken: string): string {
        const token: XitadelTokenCollection = {
            graph: graphToken,
            gdas: gdasToken
        };
        const json = serialize(token);
        return toBase64(json);
    }

    public static async getSaml(correlationId: string, token: string, replyUrl: string): Promise<XitadelSamlResponse> {
        try {
            const res = await http.send<XitadelResponse<XitadelSamlResponse>>(http.Method.GET, `/api/v1/auth/saml?wreply=${toUrlEncoded(replyUrl)}`, {
                "Authorization": `Bearer ${token}`,
                "X-Correlation-ID": correlationId,
                "Content-Type": "application/json"
            });

            if (res.status === 200) return res.data?.data as XitadelSamlResponse;

            Log.error(`${Xitadel.name}.getSaml()`, `Xitadel returned status (${res.status})`, res.error as string);

            const unexpectedError = new Error("There was an unexpected error.");

            if(res.error){
                let errorRes: XitadelErrorResponse;
                try{ errorRes = JSON.parse(res.error) as XitadelErrorResponse; }
                catch{ throw unexpectedError; }
                throw new Error(errorRes.error);
            }else{ throw unexpectedError; }
        } catch (error) {
            Log.error(`${Xitadel.name}.getSaml()`, "There was an error obtaining SAML from Xitadel", error as Error);

            throw error;
        }
    }
}