import "@/resources/styles/error-screen.css";
import React, { Fragment, useCallback } from "react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import useCorrelationId from "@/hooks/xitadel/useCorrelationId";
import { utcNow, year } from "@/util/time";
import * as browser from "@/util/browser";
import { useMsal } from "@azure/msal-react";

type ErrorScreenProps = {
  header?: string;
  error?: string;
  showControls?: boolean;
  showTenantPickerLink?: boolean;
  tenantPickerReplyUrl?: string;
};

export default function ErrorScreen({
  header,
  error,
  showControls = true,
  showTenantPickerLink = false,
  tenantPickerReplyUrl = "https://forums.xboxlive.com",
}: ErrorScreenProps) {
  const correlationId = useCorrelationId();

  const onReloadBtnClick = useCallback(() => {
    browser.reloadTab();
  }, []);

  const onCleanAndReloadBtnClick = useCallback(() => {
    browser.wipeSiteCookies();
    browser.wipeSiteLocalStorage();
    browser.reloadTab();
  }, []);

  const { instance } = useMsal();
  const signOut = useCallback(() => {
    instance.logoutRedirect();
  }, [instance]);

  const isUserHasNoSellersError = error === "User is not associated to any sellers in Partner Center";  
  
  return (
    <div className="error-screen-root">
      <div className="error-screen-container">
        <div className="error-screen-content">
          <div className="error-screen-section">
            {header ? <h1>{header}</h1> : <h1>{"Oops! :("}</h1>}
            {error && (
              <Fragment>
                {!isUserHasNoSellersError ? (
                  <p>{error}</p>
                ) : (
                  <p>
                    <span>Ensure that you are signed into the correct tenant by using the &quot;Switch Directory&quot; button below.</span><br/>
                    <span>If you have never signed into Partner Center with this account, try signing in at  <a href="https://partner.microsoft.com/dashboard" target="_blank" rel="noreferrer">partner.microsoft.com/dashboard</a>,<br/> clearing your cache, and logging in here again.</span>
                  </p>
                )}
              </Fragment>
            )}
            {!error && (
              <p>Well, this is embarrasing, there was an unexpected error.</p>
            )}
            {showControls && (
              <p>
                Please consider reloading this page.
                <br />
                You can also try cleaning browser data and reloading.
                <br />
                If the error persists please contact support and provide the
                following information:
              </p>
            )}
          </div>
          <div className="error-screen-section">
            {/* The id attributes here are for test automation purposes */}
            <div className="error-screen-txt">
              {isUserHasNoSellersError && <span>Tried both of the above steps and still can&apos;t access? </span>}
              <span>Provide these details when getting support:</span>
            </div>
            <div className="error-screen-txt">
              <b>Correlation Id: </b>
              <code id="error_correlationId">{correlationId}</code>
            </div>
            <div className="error-screen-txt">
              <b>Timestamp: </b>
              <code id="error_timestamp">{utcNow()}</code>
            </div>
          </div>
          {showControls && (
            <div className="error-screen-section">
              <DefaultButton text="Reload" onClick={onReloadBtnClick} />{" "}
              <DefaultButton
                text="Clean Browser data & Reload"
                onClick={onCleanAndReloadBtnClick}
              />
            </div>
          )}
          {showTenantPickerLink && (
            <div className="error-screen-section">
              <p>You can try switching your current directory to access:</p>
              <div style={{ textAlign: "right" }}>
                <DefaultButton
                  text="Switch Directory"
                  onClick={() =>
                    window.open(
                      `/authentication/switch-directory?wreply=${encodeURIComponent(
                        tenantPickerReplyUrl
                      )}`,
                      "_blank",
                      "popup,directories=no,titlebar=no,toolbar=no,status=no,menubar=no,resizable=no,width=400,height=600,top=75,left=1520"
                    )
                  }
                />{" "}
                <PrimaryButton text="Sign Out" onClick={signOut} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="error-screen-footer">{"© Microsoft " + year()}</div>
    </div>
  );
}
